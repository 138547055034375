@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,500&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-noto-sans-jp: "Noto Sans JP";
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-7xl: 26px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-xs: 12px;
  --font-size-5xl: 24px;
  --font-size-xl: 20px;
  --font-size-lg: 18px;

  /* Colors */
  --color-white: #fff;
  --color-darkslateblue-100: #102e7c;
  --color-darkslateblue-200: #00327d;
  --color-darkslategray: #333;
  --color-dimgray: #666;
  --color-gray-100: #fafafa;
  --color-whitesmoke-100: #f8f8f8;
  --color-lavender: #cee7fd;
  --color-midnightblue: #002074;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-13xl: 32px;
  --gap-5xs: 8px;
  --gap-base: 16px;
  --gap-37xl: 56px;
  --gap-xs: 12px;

  /* Paddings */
  --padding-base: 16px;
  --padding-13xl: 32px;
  --padding-3xs: 10px;
  --padding-5xl: 24px;
  --padding-sm: 14px;
  --padding-11xl: 30px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-11xs: 2px;

  /* border radiuses */
  --br-9xs: 4px;
  --br-21xl: 40px;
  --br-xl: 20px;
  --br-base: 16px;
  --br-11xl: 30px;
  --br-3xs: 10px;
  --br-71xl: 90px;
  --br-51xl: 70px;
  --br-31xl: 50px;
}
