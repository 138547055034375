.maskGroupIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 650px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 158px;
  left: 209px;
  font-size: 46px;
  line-height: 28px;
}
.p {
  margin: 0;
}
.title1 {
  position: absolute;
  top: 232px;
  left: 209px;
  letter-spacing: 0.02em;
  line-height: 30px;
  font-weight: 500;
  text-shadow: 0 3px 20px rgba(0, 0, 0, 0.8);
}
.zoobalinkPcTopChild {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
  width: 1440px;
  height: 70px;
}
.zoobalinkPcTopItem {
  position: absolute;
  height: 0.63%;
  width: 8.28%;
  top: 0.36%;
  right: 89.49%;
  bottom: 99.01%;
  left: 2.22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
}
.title2 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: white;
}
.title2White {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-midnightblue);
}
.titleParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.chevronRightIcon {
  position: relative;
  width: 20px;
  height: 20px;
}
.titleGroup {
  border-radius: var(--br-9xs);
  background-color: var(--color-white);
  padding: var(--padding-5xs) var(--padding-sm) var(--padding-5xs)
    var(--padding-5xl);
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.frameParent,
.frameWrapper,
.titleGroup {
  display: flex;
  flex-direction: row;
}
.frameWrapper {
  height: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  color: var(--color-midnightblue);
}
.frameParent {
  position: absolute;
  top: 32px;
  left: 701px;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-size: var(--font-size-xs);
}
.zoobalinkPcTopInner {
  position: absolute;
  top: 42px;
  left: 72px;
  width: 140px;
  height: 21px;
}
.frameChild,
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-51xl) 0 var(--br-31xl) 0;
  background-color: var(--color-whitesmoke-100);
  width: 1080px;
  height: 566px;
}
.frameItem {
  top: 429px;
  border-radius: 0 0 var(--br-31xl) 0;
  background-color: var(--color-darkslateblue-200);
  height: 137px;
}
.title7 {
  position: absolute;
  top: 106.75px;
  left: 430px;
  line-height: 28px;
}
.settingsAccountBoxChild {
  position: relative;
  width: 64px;
  height: 64px;
}
.settingsAccountBox {
  border-radius: var(--br-71xl);
  background-color: var(--color-white);
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.title8 {
  position: relative;
  line-height: 28px;
  font-weight: 500;
}
.settingsAccountBoxParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.settingsAccountBoxItem {
  position: relative;
  width: 83.85px;
  height: 63.78px;
}
.groupIcon,
.settingsAccountBoxInner {
  position: relative;
  width: 70px;
  height: 80px;
}
.groupIcon {
  width: 57.42px;
  height: 62px;
}
.settingsAccountBoxChild1 {
  position: relative;
  width: 70px;
  height: 68px;
}
.frameGroup {
  position: absolute;
  top: 175px;
  left: 54px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-37xl);
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray);
}
.vectorIcon {
  position: absolute;
  top: 51.25px;
  left: 515px;
  width: 36px;
  height: 31.5px;
}
.span {
  font-family: var(--font-noto-sans-jp);
}
.title13 {
  position: absolute;
  top: 479px;
  left: 198px;
  font-size: var(--font-size-base);
  line-height: 32px;
  color: var(--color-white);
}
.title14 {
  position: relative;
  line-height: 32px;
}
.titleWrapper {
  position: absolute;
  top: 403px;
  left: 329px;
  border-radius: var(--br-11xl);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-13xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-lg);
}
.rectangleDiv,
.rectangleParent {
  position: absolute;
  top: 461px;
  left: 180px;
  width: 1080px;
  display: none;
}
.rectangleParent {
  height: 566px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-midnightblue);
}
.rectangleDiv {
  border-radius: var(--br-51xl) 0 0 0;
  background-color: var(--color-darkslateblue-200);
  height: 195px;
}
.frameInner {
  position: absolute;
  top: -5px;
  left: -5px;
  border-radius: var(--br-51xl) 0 var(--br-31xl) 0;
  background-color: var(--color-whitesmoke-100);
  border: 5px solid var(--color-white);
  box-sizing: border-box;
  width: 1090px;
  height: 387px;
}
.title15 {
  position: relative;
  line-height: 28px;
}
.frameContainer,
.titleContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.titleContainer {
  top: 80px;
  left: 398px;
  border-radius: var(--br-11xl);
  background-color: var(--color-white);
  padding: var(--padding-3xs) var(--padding-13xl);
}
.frameContainer {
  top: 157px;
  left: 54px;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-37xl);
  font-size: var(--font-size-sm);
  color: var(--color-darkslateblue-200);
}
.rectangleGroup {
  position: absolute;
  top: 461px;
  left: 180px;
  width: 1080px;
  height: 377px;
  display: none;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-midnightblue);
}
.zoobalinkPcTopChild1 {
  position: absolute;
  top: 478px;
  left: 209px;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-100);
  width: 1024px;
  height: 340px;
}
.maskGroupIcon1 {
  position: absolute;
  top: 518px;
  left: 716px;
  width: 477px;
  height: 340px;
  object-fit: cover;
}
.it3,
.ourSpecialistsSupportContainer {
  position: absolute;
  top: 518px;
  left: 249px;
  font-size: var(--font-size-xl);
  line-height: 42px;
  color: var(--color-midnightblue);
}
.ourSpecialistsSupportContainer {
  top: 524px;
  left: 513px;
  font-size: var(--font-size-xs);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--font-roboto);
  display: none;
}
.ourSpecialistsSupport1,
.ourSpecialistsSupportContainer1 {
  position: absolute;
  left: 249px;
  font-weight: 500;
  font-family: var(--font-roboto);
  display: none;
}
.ourSpecialistsSupportContainer1 {
  top: 788px;
  font-size: 32px;
  line-height: 48px;
  color: #f1f1f1;
}
.ourSpecialistsSupport1 {
  top: 805px;
  font-size: var(--font-size-lg);
  line-height: 24px;
  color: #dedede;
}
.title21 {
  position: absolute;
  top: 618px;
  left: 249px;
  font-size: var(--font-size-xs);
  line-height: 32px;
  color: var(--color-darkslategray);
  display: inline-block;
  width: 427px;
}
.zoobalinkPcTopChild2,
.zoobalinkPcTopChild3 {
  position: absolute;
  top: 1589px;
  left: 0;
  background-color: var(--color-gray-100);
  width: 1440px;
  height: 1641px;
}
.zoobalinkPcTopChild3 {
  top: 3999px;
  background-color: var(--color-whitesmoke-100);
  height: 677px;
}
.zoobalinkPcTopChild4 {
  position: absolute;
  top: 2969px;
  left: 209px;
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  background-color: var(--color-white);
  width: 1024px;
  height: 181px;
}
.title22,
.title23,
.title24,
.title25 {
  position: absolute;
  top: 1689px;
  left: 421px;
  line-height: 24px;
  color: var(--color-dimgray);
  text-align: center;
}
.title23,
.title24,
.title25 {
  top: 1645px;
  left: 603px;
  font-size: var(--font-size-xl);
  line-height: 28px;
  color: var(--color-midnightblue);
}
.title24,
.title25 {
  top: 4079px;
  left: 662px;
}
.title25 {
  top: 2993px;
  left: 655px;
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--color-darkslategray);
}
.maskGroupIcon2,
.maskGroupIcon3 {
  position: absolute;
  top: 1753px;
  left: 209px;
  width: 320px;
  height: 240px;
  object-fit: cover;
}
.maskGroupIcon3 {
  top: 2025px;
}
.ayaneNawa {
  margin: 0;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray);
}
.microsoftEntepriseSupportName{
  font-size: larger;
  font-weight: bold;
}
.microsoftEntepriseSupportEng{
  font-size: large;
  font-weight: 300;
}
.microsoftEntepriseSupport {
  font-weight: 300;
  font-size: small;
}
.microsoftEntepriseSupportDe {
  margin: 0;
  color: var(--color-dimgray);
}
.p5,
.title26 {
  color: var(--color-darkslategray);
}
.title26 {
  position: absolute;
  top: 1764px;
  left: 569px;
  line-height: 24px;
  display: inline-block;
  width: 664px;
  font-size: var(--font-size-base);
}
.p5 {
  margin: 0;
}
.tetsuyaKiyokawa {
  margin: 0;
  font-size: var(--font-size-xs);
}
.b {
  font-family: var(--font-noto-sans-jp);
  color: var(--color-darkslategray);
}
.title27 {
  position: absolute;
  top: 2036px;
  left: 569px;
  line-height: 24px;
  display: inline-block;
  width: 664px;
  font-size: var(--font-size-base);
  color: var(--color-dimgray);
}
.rectangleIcon,
.zoobalinkPcTopChild5 {
  position: absolute;
  top: 3066px;
  left: 237px;
  width: 200px;
  height: 60px;
}
.zoobalinkPcTopChild5 {
  left: 430.55px;
}
.zoobalinkPcTopChild6,
.zoobalinkPcTopChild7,
.zoobalinkPcTopChild8 {
  position: absolute;
  top: 3066px;
  left: 622.55px;
  width: 200px;
  height: 60px;
}
.zoobalinkPcTopChild7,
.zoobalinkPcTopChild8 {
  left: 814.55px;
}
.zoobalinkPcTopChild8 {
  left: 1006.55px;
}
.title28,
.title29 {
  position: absolute;
  line-height: 28px;
}
.title28 {
  top: 3084px;
  left: 302px;
  font-weight: 500;
  color: var(--color-darkslateblue-100);
}
.title29 {
  left: 462px;
}
.title29,
.title30,
.title31 {
  top: 3084px;
  font-weight: 500;
  color: var(--color-darkslateblue-100);
}
.title30 {
  position: absolute;
  left: 654px;
  line-height: 28px;
}
.title31 {
  left: 899px;
}
.title31,
.title32,
.title33 {
  position: absolute;
  line-height: 28px;
}
.title32 {
  top: 3084px;
  left: 1063px;
  font-weight: 500;
  color: var(--color-darkslateblue-100);
}
.title33 {
  top: 3270px;
  left: 580px;
  font-size: var(--font-size-xl);
  color: var(--color-midnightblue);
  text-align: center;
}
.frameChild1 {
  position: relative;
  width: 81.5px;
  height: 81.5px;
}
.titleFrame {
  border-radius: var(--br-11xl);
  background-color: var(--color-lavender);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-13xl);
  align-items: center;
  justify-content: center;
}
.title35 {
  position: relative;
  font-size: var(--font-size-xs);
  line-height: 24px;
  color: var(--color-dimgray);
  display: inline-block;
  width: 263px;
}
.frameParent1 {
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frameParent1,
.groupParent,
.zoobalinkPcTopInner1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.groupParent {
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.zoobalinkPcTopInner1 {
  position: absolute;
  top: 3338px;
  left: 210px;
  border-radius: var(--br-xl) 0 var(--br-xl) 0;
  background-color: var(--color-gray-100);
  padding: var(--padding-11xl) var(--padding-5xl) var(--padding-xl);
  justify-content: center;
  text-align: center;
  color: var(--color-midnightblue);
}
.frameChild2 {
  position: relative;
  width: 83px;
  height: 63px;
}
.groupContainer {
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) 0 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.zoobalinkPcTopInner2,
.zoobalinkPcTopInner3 {
  position: absolute;
  top: 3338px;
  left: 566px;
  border-radius: var(--br-xl) 0 var(--br-xl) 0;
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-5xl) var(--padding-xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-darkslateblue-200);
}
.zoobalinkPcTopInner3 {
  left: 922px;
  padding: var(--padding-11xl) var(--padding-5xl) 43px;
}
.frameChild5 {
  position: relative;
  width: 70px;
  height: 80px;
  display: none;
}
.title41 {
  position: relative;
  font-size: var(--font-size-xs);
  line-height: 24px;
  color: var(--color-dimgray);
  display: inline-block;
  width: 278px;
}
.groupParent2 {
  display: flex;
  flex-direction: column;
  padding: 18px 0 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.zoobalinkPcTopInner4 {
  position: absolute;
  top: 3675px;
  left: 372px;
  border-radius: var(--br-xl) 0 var(--br-xl) 0;
  background-color: var(--color-gray-100);
  padding: var(--padding-11xl) var(--padding-5xl) var(--padding-xl);
  align-items: flex-start;
  text-align: center;
  color: var(--color-darkslateblue-200);
}
.groupParent3,
.zoobalinkPcTopInner4,
.zoobalinkPcTopInner5 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.groupParent3 {
  padding: 12px 0 0;
  align-items: center;
  gap: var(--gap-13xl);
}
.zoobalinkPcTopInner5 {
  position: absolute;
  top: 3675px;
  left: 743px;
  border-radius: var(--br-xl) 0 var(--br-xl) 0;
  background-color: var(--color-gray-100);
  padding: var(--padding-11xl) var(--padding-5xl) var(--padding-xl);
  align-items: flex-start;
  text-align: center;
  color: var(--color-darkslateblue-200);
}
.titleParent1 {
  position: absolute;
  top: 312px;
  left: 209px;
  border-radius: var(--br-9xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) 22px var(--padding-sm) 26px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
  color: var(--color-midnightblue);
}
.frameChild7,
.frameChild8 {
  position: absolute;
  top: 539.64px;
  left: 736px;
  width: 289px;
  height: 14.73px;
}
.frameChild8 {
  top: 546px;
  left: 1px;
  height: 2px;
}
.title45,
.title46,
.title47 {
  position: absolute;
  top: 171px;
  left: 5px;
  font-size: var(--font-size-xs);
  line-height: 24px;
  color: var(--color-dimgray);
  text-align: left;
  display: inline-block;
  width: 320px;
}
.title46,
.title47 {
  left: 352px;
}
.title47 {
  left: 702px;
}
.titleWrapper5 {
  position: absolute;
  top: 523px;
  left: 309px;
  border-radius: 25px;
  background-color: var(--color-lavender);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl);
  align-items: center;
  justify-content: center;
}
.title49,
.title50 {
  position: absolute;
  top: 0;
  left: 453px;
  font-size: var(--font-size-xl);
  line-height: 28px;
}
.title50 {
  top: 126px;
  left: 125px;
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--color-darkslategray);
}
.titleWrapper6,
.titleWrapper7,
.titleWrapper8 {
  position: absolute;
  top: 71px;
  left: 146px;
  border-radius: var(--br-21xl);
  background-color: var(--color-lavender);
  height: 38px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.titleWrapper7,
.titleWrapper8 {
  left: 500px;
}
.titleWrapper8 {
  left: 849px;
}
.title54,
.title55 {
  position: absolute;
  top: 126px;
  left: 440px;
  font-size: var(--font-size-base);
  line-height: 28px;
  font-weight: 500;
  color: var(--color-darkslategray);
}
.title55 {
  left: 719px;
}
.frameChild10,
.frameChild11,
.frameChild9 {
  position: absolute;
  top: 259px;
  left: 705px;
  border-radius: var(--br-11xl) 0 var(--br-11xl) 0;
  background-color: var(--color-gray-100);
  width: 320px;
  height: 240px;
}
.frameChild10,
.frameChild11 {
  left: 352px;
}
.frameChild11 {
  left: 0;
  border-radius: var(--br-11xl) 0 var(--br-21xl) 0;
}
.frameIcon,
.frameIcon1 {
  position: absolute;
  top: 287px;
  left: 765px;
  width: 210px;
  height: 196px;
}
.frameIcon1 {
  top: 285px;
  left: 407px;
  height: 187.38px;
  overflow: hidden;
}
.frameChild12,
.vectorParent {
  position: absolute;
  top: 282px;
  left: 71px;
  width: 179px;
  height: 194px;
}
.vectorParent {
  top: 938px;
  left: 208px;
  width: 1025px;
  height: 571px;
  text-align: center;
  color: var(--color-midnightblue);
}
.titleWrapper10,
.titleWrapper11,
.titleWrapper12,
.titleWrapper13,
.titleWrapper9 {
  position: absolute;
  top: 3042px;
  left: 294px;
  border-radius: var(--br-base);
  background-color: var(--color-darkslateblue-100);
  border: 3px solid var(--color-white);
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xs);
}
.titleWrapper10,
.titleWrapper11,
.titleWrapper12,
.titleWrapper13 {
  left: 484px;
}
.titleWrapper11,
.titleWrapper12,
.titleWrapper13 {
  left: 676px;
}
.titleWrapper12,
.titleWrapper13 {
  left: 869px;
}
.titleWrapper13 {
  left: 1061px;
}
.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl) 0 var(--br-xl) 0;
  background-color: var(--color-white);
  width: 480px;
  height: 449px;
}
.groupInner,
.groupItem {
  position: absolute;
  top: 370px;
  left: 90px;
  width: 300px;
  height: 1px;
}
.groupInner {
  top: 32px;
  left: 205px;
  border-radius: 50%;
  background-color: var(--color-lavender);
  width: 80px;
  height: 80px;
}
.groupChild1 {
  position: absolute;
  top: 52px;
  left: 223px;
  width: 45px;
  height: 40.98px;
}
.title61,
.title62,
.title63 {
  position: absolute;
  line-height: 28px;
}
.title61 {
  top: 128px;
  left: 163px;
  font-size: var(--font-size-5xl);
}
.title62,
.title63 {
  top: 0;
  left: 0;
}
.title63 {
  top: 4px;
  left: 141px;
  font-size: var(--font-size-sm);
  line-height: 24px;
  font-weight: 500;
  color: var(--color-dimgray);
  text-align: center;
}
.groupDiv {
  position: absolute;
  top: 389px;
  left: 158px;
  width: 164px;
  height: 28px;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslateblue-200);
}
.checkCircleParent,
.frameParent6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent6 {
  position: absolute;
  top: 240px;
  left: 104px;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: flex-start;
  text-align: center;
  color: var(--color-darkslateblue-200);
}
.titleWrapper14 {
  position: absolute;
  top: 180px;
  left: 112px;
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-13xl);
  align-items: center;
  justify-content: center;
}
.rectangleContainer {
  position: absolute;
  top: 4147px;
  left: 209px;
  width: 480px;
  height: 449px;
  color: var(--color-darkslategray);
}
.chatPlanIcon {
  position: absolute;
  top: 52px;
  left: 223px;
  width: 45px;
  height: 40.98px;
}
.title68,
.title70 {
  position: absolute;
  top: 128px;
  left: 183px;
  font-size: var(--font-size-5xl);
  line-height: 28px;
}
.title70 {
  top: 4px;
  left: 126px;
  font-size: var(--font-size-sm);
  line-height: 24px;
  font-weight: 500;
  color: var(--color-dimgray);
  text-align: center;
}
.frameWrapper1,
.titleParent2 {
  position: absolute;
  color: var(--color-darkslateblue-200);
}
.titleParent2 {
  top: 389px;
  left: 166px;
  width: 149px;
  height: 28px;
  font-size: var(--font-size-7xl);
}
.frameWrapper1 {
  top: 240px;
  left: 132px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
}
.titleWrapper15 {
  position: absolute;
  top: 180px;
  left: 110px;
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-13xl);
  align-items: center;
  justify-content: center;
}
.rectangleParent1 {
  position: absolute;
  top: 4147px;
  left: 753px;
  width: 480px;
  height: 449px;
  color: var(--color-darkslategray);
}
.groupChild4 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00022e;
  width: 1440px;
  height: 80px;
}
.title73 {
  position: absolute;
  top: 28px;
  left: 1263px;
  line-height: 24px;
}
.title74,
.title77 {
  position: relative;
  line-height: 24px;
  color: white;
}
.title77 {
  text-align: left;
}
.titleParent3 {
  position: absolute;
  top: 28px;
  left: 767px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.groupChild5 {
  position: absolute;
  top: 30px;
  left: 80px;
  width: 135px;
  height: 21px;
}
.rectangleParent2 {
  position: absolute;
  top: 5068px;
  left: 0;
  width: 1440px;
  height: 80px;
  text-align: center;
  font-size: var(--font-size-xs);
}
.groupChild6,
.zoobalinkPcTopChild9 {
  position: absolute;
  top: 2297px;
  left: 209px;
  background-color: var(--color-white);
  width: 1024px;
  height: 656px;
}
.groupChild6 {
  top: 0;
  left: 88px;
  border-radius: 50%;
  background-color: var(--color-gray-100);
  width: 100px;
  height: 100px;
}
.groupChild7 {
  position: absolute;
  height: 23.27%;
  width: 18.93%;
  top: 9.91%;
  right: 41.07%;
  bottom: 66.81%;
  left: 40%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.title78 {
  top: 160px;
  left: 0;
  line-height: 24px;
  display: inline-block;
  width: 280px;
}
.icon,
.title78,
.title79 {
  position: absolute;
}
.title79 {
  top: 124px;
  left: 98px;
  font-size: var(--font-size-sm);
  line-height: 28px;
  font-weight: 500;
  color: var(--color-darkslategray);
  text-align: center;
}
.icon {
  top: 2383px;
  left: 366px;
  width: 50px;
  height: 46.59px;
  overflow: hidden;
  display: none;
}
.ellipseParent,
.groupChild9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 232px;
}
.groupChild9 {
  height: 21.09%;
  width: 17.86%;
  top: 11.21%;
  right: 41.79%;
  bottom: 67.7%;
  left: 40.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.title81 {
  position: absolute;
  top: 124px;
  left: 63px;
  font-size: var(--font-size-sm);
  line-height: 28px;
  font-weight: 500;
  color: var(--color-darkslategray);
  text-align: center;
}
.ellipseGroup {
  top: 288px;
  left: 0;
  width: 280px;
  height: 232px;
}
.ellipseGroup,
.icon1,
.title83 {
  position: absolute;
}
.icon1 {
  top: 24px;
  left: 113px;
  width: 50px;
  height: 52.03px;
  overflow: hidden;
}
.title83 {
  top: 124px;
  left: 70px;
  font-size: var(--font-size-sm);
  line-height: 28px;
  font-weight: 500;
  color: var(--color-darkslategray);
  text-align: center;
}
.ellipseContainer {
  position: absolute;
  top: 0;
  left: 328px;
  width: 280px;
  height: 232px;
}
.icon2,
.title85 {
  position: absolute;
}
.icon2 {
  top: 26px;
  left: 113px;
  width: 50px;
  height: 48.19px;
  overflow: hidden;
}
.title85 {
  top: 124px;
  left: 49px;
  font-size: var(--font-size-sm);
  line-height: 28px;
  font-weight: 500;
  color: var(--color-darkslategray);
  text-align: center;
}
.ellipseParent1 {
  position: absolute;
  top: 288px;
  left: 328px;
  width: 280px;
  height: 256px;
}
.icon3 {
  position: absolute;
  top: 27px;
  left: 116px;
  width: 45px;
  height: 45.28px;
  overflow: hidden;
}
.title87 {
  position: absolute;
  top: 124px;
  left: 46px;
  font-size: var(--font-size-sm);
  line-height: 28px;
  font-weight: 500;
  color: var(--color-darkslategray);
  text-align: center;
}
.chatIcon,
.ellipseParent2 {
  position: absolute;
  top: 0;
  left: 656px;
  width: 280px;
  height: 232px;
}
.chatIcon {
  top: 27px;
  left: 116px;
  width: 45px;
  height: 45.28px;
  overflow: hidden;
}
.ellipseParent3,
.groupParent4 {
  position: absolute;
  top: 288px;
  left: 656px;
  width: 280px;
  height: 208px;
}
.groupParent4 {
  top: 2353px;
  left: 253px;
  width: 936px;
  height: 544px;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray);
}
.maskGroupIcon4 {
  position: absolute;
  top: 4676px;
  left: 0;
  width: 1440px;
  height: 392px;
  object-fit: cover;
}
.title90 {
  position: absolute;
  top: 0;
  left: 212px;
  line-height: 28px;
  font-weight: 500;
}
.groupChild14 {
  position: absolute;
  top: 44px;
  left: 202px;
  width: 226.1px;
  height: 35px;
}
.titleParent5 {
  position: absolute;
  top: 176px;
  left: 211px;
  border-radius: var(--br-9xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 28px var(--padding-base) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
  font-size: var(--font-size-sm);
  color: var(--color-darkslateblue-100);
}
.title92 {
  position: absolute;
  top: 119px;
  left: 0;
  font-size: var(--font-size-7xl);
  line-height: 28px;
}
.titleParent4 {
  position: absolute;
  top: 4756px;
  left: 397px;
  width: 647px;
  height: 232px;
  font-size: var(--font-size-base);
}
.zoobalinkPcTopChild10 {
  position: absolute;
  top: 84px;
  left: 209px;
  width: 174px;
  height: 26px;
  display: none;
}
.zoobalinkPcTop {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 5148px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-noto-sans-jp);
}
